// export const BaseUrl = "https://457c-110-39-1-50.ngrok-free.app/api/v1";
export const BaseUrl = "https://api.thesalonsubstitute.com/api/v1";
//export const BaseUrl = "http://localhost:5000/api/v1";
export const socketUrl = "https://api.thesalonsubstitute.com";
// export const socketUrl = "https://f240-206-42-127-174.ngrok.io";
// export const socketUrl = "http://localhost:5000";

const vapidKey =
  "BAB1jum_VPvTEqnGWVoVRl9uMOHPSs1wTEkVBvfZ6MUnGersUg5Quoqeae3PQXvGX4rG_XngslGAaxSiE6WQSPI";
const Subscription = {
  basicSubscription: "price_1LVvucAOwInuazNMrwiZgQB8",
  proSubscription: "price_1LVvv5AOwInuazNMYReaxIED",
  premSubscription: "price_1LVvvjAOwInuazNMQXxGl0L7",
};
const s3Expereience = "https://subsalon-app.s3.amazonaws.com/expereience/";
const roleEnum = {
  professional: "professional",
  owner: "owner",
};
const jobType = {
  partTime: "Part-time",
  fullTime: "Full-time",
};
const jobStatus = {
  Open: "Open",
  Close: "Closed",
  reOpen: "Re-open",
};

const jobRequestEnum = {
  accepted: "Accept",
  reject: "Reject",
};

const rateType = {
  perHour: "Per Hour",
  commission: "Commission",
};

const ArrMap = [
  "Charlotte",
  "Raleigh",
  "Greensboro",
  "Durham",
  "Winston-Salem",
  "Fayetteville",
  "Cary",
  "Wilmington",
  "High Point",
  "Concord",
  "Asheville",
  "Greenville",
  "Gastonia",
  "Jacksonville",
  "Apex",
  "Huntersville",
  "Chapel Hill",
  "Burlington",
  "Kannapolis",
  "Rocky Mount",
  "Mooresville",
  "Wake Forest",
  "Wilson",
  "Hickory",
  "Holly Springs",
  "Indian Trail",
  "Fuquay-Varina",
  "Salisbury",
  "Monroe",
  "Goldsboro",
  "Garner",
  "Cornelius",
  "New Bern",
  "Morrisville",
  "Sanford",
  "Matthews",
  "Statesville",
  "Clayton",
  "Thomasville",
  "Asheboro",
  "Kernersville",
  "Mint Hill",
  "Leland",
  "Shelby",
  "Waxhaw",
  "Clemmons",
  "Carrboro",
  "Knightdale",
  "Kinston",
  "Lexington",
  "Harrisburg",
  "Elizabeth City",
  "Lumberton",
  "Mebane",
  "Lenoir",
  "Boone",
  "Pinehurst",
  "Mount Holly",
  "Hope Mills",
  "Morganton",
  "Graham",
  "Havelock",
  "Albemarle",
  "Stallings",
  "Murraysville",
  "Southern Pines",
  "Eden",
  "Laurinburg",
  "Belmont",
  "Davidson",
  "Roanoke Rapids",
  "Hendersonville",
  "Henderson",
  "Anderson Creek",
  "Reidsville",
  "Piney Green",
  "Lewisville",
  "Weddington",
  "Newton",
  "Lake Norman of Iredell",
  "Archdale",
  "Myrtle Grove",
  "Smithfield",
  "Spring Lake",
  "Wendell",
  "Kings Mountain",
  "Lincolnton",
  "Elon",
  "Summerfield",
  "Pineville",
  "Winterville",
  "Tarboro",
  "Mount Airy",
  "Waynesville",
  "Rolesville",
  "Spout Springs",
  "Hillsborough",
  "Washington",
  "Morehead City",
  "St. Stephens",
  "Ogden",
  "Aberdeen",
  "Rockingham",
  "Gibsonville",
  "Wesley Chapel",
  "Oak Island",
  "Oxford",
  "Lake Norman of Catawba",
  "Kings Grant",
  "Butner",
  "Conover",
  "Black Mountain",
  "Dunn",
  "Half Moon",
  "Roxboro",
  "Clinton",
  "Fletcher",
  "Woodfin",
  "Zebulon",
  "Siler City",
  "Etowah",
  "Kill Devil Hills",
  "Brevard",
  "Porters Neck",
  "Marion",
  "Oak Ridge",
  "Forest City",
  "King",
  "Mills River",
  "Trinity",
  "Hampstead",
  "St. James",
  "Cullowhee",
  "Unionville",
  "Selma",
  "Carolina Beach",
  "Marvin",
  "Boiling Spring Lakes",
  "Silver Lake",
  "Cherryville",
  "East Flat Rock",
  "Hamlet",
  "Dallas",
  "Stokesdale",
  "Mocksville",
  "Walkertown",
  "Angier",
  "Nashville",
  "Bessemer City",
  "Cramerton",
  "James City",
  "Moyock",
  "Long View",
  "Whispering Pines",
  "Williamston",
  "Pleasant Garden",
  "Wadesboro",
  "Sawmills",
  "Archer Lodge",
  "Ayden",
  "Creedmoor",
  "Northchase",
  "Granite Falls",
  "Ahoskie",
  "Midway",
  "Midland",
  "Wrightsboro",
  "Carolina Shores",
  "Raeford",
  "Whiteville",
  "Valdese",
  "Weaverville and Randleman",
  "Locust",
  "Erwin",
  "Pittsboro",
  "Ranlo",
  "Beaufort",
  "Lillington",
  "Boiling Springs",
  "Royal Pines",
  "Farmville",
  "Newport",
  "China Grove",
  "Seven Lakes",
  "Holly Ridge",
  "Canton",
  "Edenton",
  "Swannanoa",
  "Mountain Home",
  "Shallotte",
  "Sunset Beach",
  "North Wilkesboro",
  "Franklin",
  "Spindale",
  "Brices Creek",
  "Westport",
  "Southport",
  "Surf City",
  "Elroy",
  "Benson",
  "Forest Oaks",
  "Elkin",
  "Mount Olive",
  "Trent Woods and Stanley",
  "Bethlehem",
  "Wingate",
  "Emerald Isle",
  "Dana",
  "Swansboro",
  "Troutman",
  "Hudson",
  "Bayshore and Mountain View",
  "Kitty Hawk",
  "Maiden",
  "Jamestown",
  "Landis",
  "Gamewell",
  "Lowell",
  "Rockfish",
  "Glen Raven",
  "Eastover",
  "Tabor City",
  "Rutherfordton",
  "Briar Chapel",
  "River Road",
  "Wilkesboro",
  "Fairview town",
  "Flat Rock village",
  "Rural Hall",
  "Buies Creek",
  "Enochville",
  "Windsor",
  "Red Oak",
  "East Rockingham",
  "Lake Park",
  "Spencer and Wallace",
  "Avery Creek",
  "Lake Junaluska",
  "Skippers Corner",
  "Tyro",
  "Mineral Springs",
  "Plymouth",
  "Lake Royale",
  "Nags Head",
  "Lowesville",
  "Southern Shores",
  "Green Level",
  "Bermuda Run",
  "Elizabethtown",
  "Mar-Mac",
  "Wallburg",
  "Red Springs",
  "Burgaw",
  "Welcome",
  "Louisburg",
  "Granite Quarry",
  "Denver",
  "Grandy",
  "Troy",
  "River Bend",
  "South Rosemary",
  "Pembroke",
  "Fairfield Harbour",
  "Yadkinville",
  "Edneyville",
  "Carthage",
  "Fairview",
  "Fearrington Village",
  "Warsaw",
  "Cajah's Mountain",
  "Liberty",
  "La Grange",
  "Wilson's Mills",
  "Wentworth",
  "Tobaccoville",
  "Sylva",
  "Franklinton",
  "Marshville",
  "Emma",
  "Murfreesboro",
  "Grifton",
  "Swepsonville",
  "Cricket",
  "Belville",
  "Wrightsville Beach",
  "Mayodan",
  "Norwood",
  "Four Oaks",
  "Sneads Ferry",
  "Taylorsville",
  "Rockwell",
  "Jonesville",
  "Broad Creek and Brogden",
  "Richlands",
  "Haw River",
  "Cape Carteret",
  "Laurel Park",
  "Fruitland and Spruce Pine",
  "Valley Hill",
  "Calabash",
  "Coats",
  "Fairmont",
  "Horse Shoe",
  "Oakboro",
  "Kure Beach",
  "Pine Level",
  "Cherokee and Madison",
  "Icard and Youngsville",
  "Maxton",
  "Wanchese",
  "Moravian Falls",
  "St. Pauls and Mulberry",
  "Badin",
  "Mars Hill",
  "Neuse Forest",
  "Hertford",
  "Polkton",
  "Biscoe",
  "Enfield",
  "Pumpkin Center",
  "Dudley",
  "Yanceyville",
  "Cedar Point",
  "Drexel",
  "Fairplains",
  "Ramseur",
  "Governors Village",
  "Mount Pleasant",
  "Claremont",
  "Sparta",
  "Sharpsburg",
  "Maggie Valley",
  "Andrews",
  "Hildebran",
  "Bent Creek",
  "Bladenboro",
  "Hemby Bridge",
  "Sea Breeze",
  "Rocky Point",
  "Burnsville",
  "Scotland Neck",
  "Murphy",
  "Manteo",
  "Plain View",
  "Stanfield",
  "East Spencer",
  "Tryon",
  "Buxton",
  "Chadbourn and Kenly",
  "Stony Point",
  "Snow Hill",
  "Walnut Cove",
  "Glen Alpine and Navassa",
  "Connelly Springs",
  "Denton",
  "Jefferson",
  "Governors Club",
  "Pinebluff",
  "Salem",
  "Millers Creek",
  "Bryson City",
  "Pilot Mountain",
  "West Canton",
  "Weldon",
  "Biltmore Forest",
  "Belhaven",
  "Atlantic Beach",
  "Cherry Branch and Blowing Rock",
  "Hoopers Creek",
  "Pine Knoll Shores and Dobson",
  "Toast",
  "Clyde",
  "Cypress Landing",
  "Bethel",
  "Vander",
  "Lake Lure",
  "Princeton",
  "Rose Hill",
  "Maury",
  "Hays",
  "Saxapahaw",
  "New Hope",
  "Foxfire",
  "Cordova",
  "Brandywine Bay",
  "Nebo",
  "Bonnetsville",
  "Harkers Island",
  "Stoneville",
  "Foscoe",
  "Spring Hope",
  "Northlakes",
  "Broadway and Barker Heights",
  "Stedman",
  "Lake Waccamaw",
  "Robersonville",
  "Princeville",
  "Springdale",
  "West Jefferson",
  "Rutherford College",
  "Franklinville",
  "Elm City and Five Points",
  "Robbins",
  "Boonville",
  "Fremont",
  "Pinetops",
  "Balfour",
  "Mount Gilead",
  "Southmont",
  "Roseboro",
  "Advance",
  "White Plains",
  "Germanton",
  "Laurel Hill",
  "Beulaville",
  "West Marion",
  "Silver City",
  "Walnut Creek",
  "JAARS",
  "Highlands",
  "Cove Creek",
  "Dortches",
  "South Henderson",
  "Bayboro",
  "Banner Elk",
  "Lucama",
  "Columbus",
  "Flat Rock",
  "North Topsail Beach",
  "Castle Hayne and Alamance",
  "Gaston",
  "Rhodhiss and Stem",
  "Vass",
  "Gorman",
  "Ocracoke",
  "Cooleemee",
  "Barker Ten Mile",
  "Norlina",
  "Holden Beach",
  "Brunswick",
  "McLeansville",
  "Camden",
  "Middlesex",
  "Ocean Isle Beach",
  "McAdenville",
  "Oriental",
  "Rowland",
  "Garysburg",
  "Montreat",
  "Light Oak",
  "Belwood",
  "Ellerbe",
  "Rich Square",
  "Hollister",
  "Vanceboro",
  "Cleveland",
  "Warrenton",
  "Columbia",
  "White Lake",
  "Woodlawn",
  "Magnolia",
  "Maysville",
  "South Weldon",
  "Faith",
  "Hightsville",
  "Alexis",
  "Old Fort",
  "Star",
  "Prospect and Candor",
  "Pleasant Hill and Grover",
  "Efland",
  "Arrowhead Beach",
  "Moncure",
  "Frisco",
  "Marshall",
  "Faison",
  "Peletier",
  "Bunnlevel",
  "Northwest",
  "Red Cross",
  "Duck",
  "Potters Hill",
  "Pinnacle",
  "Rougemont and Stantonsburg",
  "Cape Colony",
  "Aulander",
  "Barnardsville",
  "Conway",
  "Alliance and Ellenboro",
  "Cashiers",
  "Chocowinity",
  "Manns Harbor",
  "Newland",
  "Jackson Heights and Fair Bluff",
  "Rosman",
  "Cliffside",
  "Pikeville",
  "Bogue and Catawba",
  "Grantsboro and Yadkin College",
  "Dobbins Heights",
  "Kenansville",
  "Riegelwood",
  "Black Creek",
  "Sedalia and Beech Mountain",
  "Kingstown and Bowdens",
  "Taylortown",
  "Misenheimer",
  "Wade",
  "East Bend",
  "Wagram",
  "Fallston",
  "Whitakers and Winton",
  "New London",
  "Mamers and Saluda",
  "Carolina Meadows",
  "Atlantic",
  "Clarkton",
  "Stokes",
  "High Shoals",
  "Garland",
  "Whitsett",
  "Richfield",
  "Patterson Springs",
  "Lawndale",
  "Greenevers",
  "Newton Grove",
  "Bailey",
  "Littleton, Harmony, and Winfall",
  "Elk Park and Deercroft",
  "Marble",
  "Old Hundred and Woodland",
  "Ossipee",
  "Minnesott Beach and Iron Station",
  "Deep Run",
  "Ingold and Robbinsville",
  "Seaboard and Varnamtown",
  "Hot Springs",
  "Elrod",
  "Polkville",
  "Bolton",
  "Parkton",
  "Gloucester",
  "Topsail Beach",
  "Micro",
  "Avon",
  "Bayview",
  "Ruffin",
  "Roper",
  "Salemburg",
  "Gibson",
  "Pink Hill",
  "Ansonville",
  "Henrietta",
  "Bakersville",
  "Belvoir",
  "Teachey and Aurora",
  "Millingport",
  "Caroleen",
  "Brookford and Hallsboro",
  "Ronda",
  "Hayesville and Hookerton",
  "Sandyfield",
  "Scotch Meadows",
  "Lewiston Woodville and Arapahoe",
  "Swan Quarter",
  "Jackson",
  "East Arcadia and St. Helena",
  "Jamesville",
  "Hoffman",
  "Aquadale",
  "Marshallberg",
  "Macclesfield",
  "Lilesville",
  "Vann Crossroads",
  "Peachland",
  "Caswell Beach",
  "Lattimore and Staley",
  "Simpson",
  "South Mills",
  "Grimesland",
  "Washington Park",
  "Fountain",
  "Webster",
  "Coinjock",
  "Bell Arthur",
  "Cove City and Sugar Mountain",
  "Hatteras",
  "Altamahaw and Roberdel",
  "Farmington",
  "Chowan Beach",
  "Bostic",
  "Bennett",
  "Saratoga",
  "Shannon and Ruth",
  "Bethania",
  "Bridgeton",
  "Dover",
  "Bunn",
  "Kelly",
  "Falcon, Spivey's Corner, and Stovall",
  "Morven",
  "Valle Crucis",
  "Ashley Heights",
  "Seven Devils and Calypso",
  "Sims",
  "Waco",
  "Atkinson",
  "Forest Hills",
  "Gold Hill",
  "Casar",
  "Centerville and Glenwood",
  "Hamilton",
  "Cedar Rock",
  "Mooresboro and Linville",
  "Hiddenite",
  "Rennert",
  "Cofield",
  "Momeyer",
  "Sandy Creek",
  "Bald Head Island and Pollocksville",
  "Hobgood",
  "Dublin",
  "Oak City",
  "Castalia and Gatesville",
  "Gerton",
  "Raemon",
  "White Oak",
  "Cameron",
  "Lowgap",
  "Bath",
  "Sunbury and East Laurinburg",
  "Vandemere",
  "Parmele and Goldston",
  "Seagrove",
  "Trenton",
  "Ivanhoe",
  "Davis",
  "Indian Beach",
  "Stonewall",
  "Colerain",
  "Turkey",
  "Dillsboro",
  "Eureka",
  "Walstonburg and Creswell",
  "Kelford",
  "Conetoe and Earl",
  "Watha and Severn",
  "Powellsville",
  "Askewville and Roxobel",
  "Rodanthe",
  "Keener",
  "Autryville",
  "Milwaukee",
  "Halifax",
  "Fairfield",
  "Pantego",
  "Boardman and Harrells",
  "Love Valley",
  "Milton",
  "Engelhard and Godwin",
  "Butters",
  "Bolivia",
  "Everetts",
  "Mesic",
  "Crossnore",
  "Crouse",
  "Danbury",
  "Chimney Rock Village",
  "Linden",
  "Jackson Springs",
  "Cerro Gordo",
  "Delco and Kittrell",
  "Lansing",
  "Evergreen",
  "Proctorville",
  "Macon",
  "Wakulla",
  "Marietta",
  "Middleburg",
  "Glenville, Smithtown, Norman, and Long Creek",
  "Grandfather Village",
  "McFarlan",
  "Salvo",
  "Bowmore and McDonald",
  "Tar Heel",
  "Harrellsville",
  "Bear Grass",
  "Lumber Bridge",
  "Pinetown",
  "Como",
  "Graingers",
  "Speed",
  "Lasker",
  "Raynham",
  "Dundarrach",
  "Orrum",
  "Seven Springs and Waves",
  "Rex",
  "Blue Clay Farms",
  "Falkland",
  "Hassell",
  "Lake Santeetlah",
  "Hobucken",
  "Leggett",
  "Delway",
  "Dellview",
  "Fontana Dam",
  "Chinquapin",
];

export {
  roleEnum,
  jobType,
  jobStatus,
  jobRequestEnum,
  Subscription,
  vapidKey,
  ArrMap,
  rateType,
  s3Expereience,
};
