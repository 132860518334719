const Endpoint = {
  LoginApi: "auth/login/",
  RegisApi: "auth/register/",
  ForgetPassApi: "auth/forgot-password/",
  ResetPassApi: "auth/reset-password/",
  AllJobsApi: "job/",
  updateProfile: "users/updateProfile",
  AllSalonsApi: "salon/getAllSaloon/",
  FavJobsApi: "job/favouriteJob/",
  RevFavJobsApi: "job/removefavouriteJob/",
  FavSaloonsApi: "salon/favouriteSaloon",
  RevFavSaloonsApi: "salon/removefavouriteSaloon",
  publicJob: "/public/",
  getMyAppliedJob: "applicant/myjob",
  applyJob: "applicant/apply",
  getAllMyJobRequest: "jobRequest/getRequest",
  covidApi: "covid/addVaccination",
  experienceApi: "experience/addExperience",
  myFavouritejobs: "job/myfavourite",
  myFavouriteSalon: "job/myfavouriteSalon",
  beforeLogin : "users/checkSocialLogin/",
  socialLogin: "auth/socialLogin",
  createSalon: "salon/createSaloon",
  allProfessiional: "users/allProfessional",
  actionJobRes: "jobRequest/action",
  offerJob: "jobRequest/offer",
  saloonJobs: "salon/saloonJob/",
  ownerOpenJob: "job/open",
  ownerCloseJob: "job/close",
  getSingleJob: "job/getJob/",
  getSingleJobforNotfctn: "job/jobDetail/",
  FavProfessionalsApi: "users/favProvider",
  RevFavProfessionalsApi: "users/removeProvider",
  getFavAllProvider: "users/getFavAllProvider",
  getApplicants: "applicant/job/",
  postAJob: "job/create",
  deleteAJob: "/job/",
  ReOpen: "job/reopen/",
  getallJobFromSingleSaloon: "salon/saloonJob/",
  allApplicants: "applicant/job/",
  getApplicantProfile: "applicant/profile/",
  writeReview: "review/add-review/",
  getAppliantReview: "review/getreview/",
  changeUserDetail: "users/userDetail",
  AllaPPlicantsSaloon: "applicant/allApplicantsfromSalon/",
  availability: "salonAvailable/time",
  professionProfile: "users/professionProfile/",
  availabilityBack: "salonAvailable/getSaloonSetting",
  emailVerified: "auth/verify-email/",
  resendVerifyEmail: "auth/resend-verify-email/",
  ownerDashboard: "users/ownerDashboard",
  createConversation: "conv/create-converstion",
  getAllConversation: "conv/get-converstions/",
  getMessage: "message/get-messages/",
  getReadMsg: "message/read-all",
  tagging: "service/allService",
  checkConversation: "conv/check-converstion",
  delConversation: "conv/del-converstions/",
  addCard: "subscription/addCard",
  delCard: "subscription/deleteCard",
  createSubscription: "subscription/createSubription",
  getImage: "salon/getImage",
  updateSubscription: "subscription/changeSubscription",
  cancelSubscripton: "subscription/cancelSubscription",
  getAllCards: "subscription/getAllCards",
  getAllNotification: "notification/allNotifcation",
  emailPerference: "users/emailPereferences",
  getSaloonbyId: "salon/saloon/",
  inviteSaloon: "salon/invite",
  PrimaryCard: "subscription/selectCard",
  subscribeNewsLetter:"subscribe",
  notificationToken:"notification/notificationToken/",
  readAllnotify:"notification/readAllNotification",
  aciontOnApplicant:"applicant/action",
  deleteAccountApi:"users/delete/",
  createFeedback:"users/createFeedback",
  servicesGet: "service/allService/",
  trial_type:"subscription/startTrial",

};

export { Endpoint };
